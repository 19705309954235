import * as React from "react";
import {useNavigate} from "react-router-dom";
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import {Helmet} from "react-helmet";
import Badge from "react-bootstrap/Badge";
import API from "../../api";
import {useEffect, useState} from "react";
import moment from "moment";

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { classNames } from 'primereact/utils';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { InputText } from 'primereact/inputtext';
import { IconField } from 'primereact/iconfield';
import { InputIcon } from 'primereact/inputicon';
import { Dropdown } from 'primereact/dropdown';
import { MultiSelect } from 'primereact/multiselect';
import { Tag } from 'primereact/tag';
import { TriStateCheckbox } from 'primereact/tristatecheckbox';
import LoadingDiv from "../../components/LoadingDiv";



function Users() {
	let navigate = useNavigate();

	const [users, setUsers] = useState([])
	const [isLoading, setLoading] = useState(false)
	const [filters, setFilters] = useState({
		global: { value: null, matchMode: FilterMatchMode.CONTAINS },
		firstName: {value: null, matchMode: FilterMatchMode.STARTS_WITH},
		email: {value: null, matchMode: FilterMatchMode.STARTS_WITH},
	});
	const [globalFilterValue, setGlobalFilterValue] = useState('');
	const onGlobalFilterChange = (e) => {
		const value = e.target.value;
		let _filters = { ...filters };

		_filters['global'].value = value;

		setFilters(_filters);
		setGlobalFilterValue(value);
	};

	const renderHeader = () => {
		return (
			<div className="flex justify-content-end">
				<IconField iconPosition="left">
					<InputIcon className="pi pi-search" />
					<InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Keyword Search" />
				</IconField>
			</div>
		);
	};
	useEffect(() => {
		setLoading(true);
		API.get("admin/users")
			.then(response => setUsers(response.data))
			.finally(() => setLoading(false));
	}, [])

	const onRowSelect = (event) => {
		navigate(`/admin/users/${event.data.id}`)
	};

	const thumbnailTemplate = (video) => {
		return <img src={video.thumbnail} alt={video.title} width={100} />
	}

	const activeTemplate = (user) => {
		if (user.active) {
			return <Badge bg="success">Active</Badge>
		}
		if (!user.active) {
			return <Badge bg="danger">Disabled</Badge>
		}
	}

	const createdDateTemplate = (user) => {
		return moment(user.created_at).format('YYYY-MM-DD');
	}

	const userNameTemplate = (user) => {
		let fname = '';
		let lname = '';
		if (user.firstName) {
			fname = user.firstName;
		}
		if (user.lastName) {
			lname = user.lastName;
		}

		return `${fname} ${lname}`;
	}
	const header = renderHeader();

	return (
		<>
			<Helmet>
				<title>Users | MyGolfDNA.com</title>
				<meta name="description" content="Users"/>
			</Helmet>
			<div className="mt-4">
				<Row>
					<Col>
						<h2>Users</h2>
						{isLoading ? <LoadingDiv/> :
							<div className="card">
								<DataTable
									value={users}
									paginator
									rows={200}
									rowsPerPageOptions={[50, 100, 200]}
									tableStyle={{minWidth: '50rem'}}
									selectionMode="single"
									onRowSelect={onRowSelect}
									filters={filters}
									filterDisplay="row"
									globalFilterFields={['firstName', 'email']} header={header} emptyMessage="No customers found."
								>
									<Column field="firstName" sortable header="Name" filter filterPlaceholder="Search by name" body={userNameTemplate}/>
									<Column field="email" filter filterPlaceholder="Search by email" sortable header="Email"/>
									<Column field="created_at" sortable header="Signup Date" body={createdDateTemplate}/>
								</DataTable>
							</div>
						}
					</Col>
				</Row>
			</div>
		</>
	);
}

export default Users;

import CommentForm from "./CommentForm";
import VideoComment from "./VideoComment";

function VideoComments() {

	return (
		<>
			<CommentForm/>
			<VideoComment/>
		</>
	)
}

export default VideoComments;

import Comment from "./Comment";

function Comments(comments) {
	const commentsList = comments.comments;

	function recursiveComments(comments) {
		return comments.map((comment, index) => {
			return (
				<div key={comment.id}>
						<div className={`comments ${comment.parentId ? 'ms-3' : ''}`}>
							{comment ? <Comment comment={comment}/> : null}
							{recursiveComments(comment.comments)}
						</div>
				</div>
			)
		})
	}

	return recursiveComments(commentsList)

}

export default Comments;

import {useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import API from "../../api";
import LoadingDiv from "../LoadingDiv";
import Comments from "./Comments";

function VideoComment() {
	const [videoComments, setVideoComments] = useState([])
	const [isLoading, setLoading] = useState(false)
	let { slug } = useParams();

	useEffect(() => {
		setLoading(true);
		API.get(`videoComments/${slug}`)
			.then(response => setVideoComments(response.data))
			.finally(() => setLoading(false));
	}, [slug])

	return (
		<>
			<div id="comments-container" className="comment-container">
				{isLoading ? <LoadingDiv/> : <Comments comments={videoComments}/>}
			</div>
		</>
	)
}

export default VideoComment;

import * as React from "react";
import { Outlet } from "react-router-dom";
import EmptyNavigation from "./../components/Navigations/EmptyNavigation";
import Footer from "../components/Footer";

function SubscriptionLayout({ backgroundColor = "#fff"}) {
	return (
		<div style={{backgroundColor, minHeight: "100vh"}}>
			<EmptyNavigation/>
			<Outlet/>
			<Footer className="bg-dark-blue"/>
		</div>
	);
}

export default SubscriptionLayout;

import React, {useEffect, useState} from 'react';
import {useAuth} from "../AuthUser";
import API from "../api";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Button from "react-bootstrap/Button";

const truncate = (text, max, ending = '…') => text.length > max ? text.substr(0, max - ending.length) + ending : text;

export const SwiperInstructors = ({ videos = []}) => {
	const auth = useAuth();
	const slides = [];
	const responsive = {
		desktop: {
			breakpoint: { max: 3000, min: 1024 },
			items: 3,
			partialVisibilityGutter: 40 // this is needed to tell the amount of px that should be visible.
		},
		tablet: {
			breakpoint: { max: 1024, min: 464 },
			items: 2,
			partialVisibilityGutter: 30 // this is needed to tell the amount of px that should be visible.
		},
		mobile: {
			breakpoint: { max: 464, min: 0 },
			items: 1,
			partialVisibilityGutter: 30 // this is needed to tell the amount of px that should be visible.
		}
	};

	const [instructors, setInstructors] = useState([])
	const [isLoading, setLoading] = useState(false)

	useEffect( () => {
		setLoading(true);
		API.get(`instructors`)
			.then(data => setInstructors(data.data))
			.finally(() => setLoading(false));
	}, []);


	for (let i = 0; i < instructors.length; i += 1) {
		slides.push(
			<div key={`slide-${instructors[i]}${Math.random()}`} className="p-2">
				<a style={{textDecoration: "none"}} href={`/pricing/instructor/${instructors[i]['id']}`} title={instructors[i]['firstName']}>
					<div className="img-instructor">
					<img
							 className="img-fluid"
							 src={instructors[i]['thumbnail']}
							 alt={instructors[i]['firstName']}
							 //width={700}
							 style={{
								 borderRadius: "4px",
								 //width: 800
							 }}
						/>
						<div className="position-absolute bottom-0 p-4" style={{zIndex: 10}}>
							<h5 className="text-white"><strong>{instructors[i]['firstName']} {instructors[i]['lastName']}</strong></h5>
							<Button variant="secondary" size="sm" type="link" >Learn More</Button>
						</div>
					</div>
				</a>
			</div>
		);
	}

	return (
		<React.Fragment>
			<Carousel
				partialVisible={true}
				responsive={responsive}
				itemClass=""
			>
			{slides}
			</Carousel>
		</React.Fragment>
	);
};

import Dropzone from "react-dropzone-uploader-with-credentials";
import '../assets/css/upload.css';
import API from "../api";
import {useAuth} from "../AuthUser";
import {toast} from "react-toastify";
import {useNavigate} from "react-router-dom";
import {Col, Row, Container, Card, Modal, Button} from "react-bootstrap";
import {useState} from "react";

const notify = (message) =>
	toast.error(message, {
		position: toast.POSITION.TOP_RIGHT,
	});



const Layout = ({ input, previews, submitButton, dropzoneProps, files, extra: { maxFiles } }) => {
	return (
		<div>
			{previews}

			<div {...dropzoneProps}>
				{files.length < maxFiles && input}
				<textarea name="notes"/>

				{files.length > 0 && submitButton}
			</div>
		</div>
	)
}
const Input = ({ accept, onFiles, files, getFilesFromEvent }) => {
	const text = files.length > 0 ? 'Add more files' : 'Choose files'

	return (
			<div className="input-group">
				<input type="file" className="form-control" id="inputGroupFile04" aria-describedby="inputGroupFileAddon04"
							 aria-label="Upload"
							 style={{ display: 'none' }}
							 accept={accept}
							 multiple
							 onChange={e => {
								 getFilesFromEvent(e).then(chosenFiles => {
									 onFiles(chosenFiles)
								 })
							 }}>
					<button className="btn btn-outline-secondary" type="button" id="inputGroupFileAddon04">{text}</button>
				</input>
			</div>
	)
}

const NoDropzoneLayout = ({ previews, submitButton, input, files, dropzoneProps, extra: { maxFiles } }) => {
	let { user } = useAuth();
	const { ref, className, style } = dropzoneProps
	return (
		<>
			<Container>
				<Row className="justify-content-center mb-3">
					<Col lg={5} md={12} sm={12}>
						<div className="" style={{padding:'56.25% 0 0 0',position:'relative'}}>
							<iframe
								src="https://player.vimeo.com/video/826506355?h=51c8710145&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
								frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen
								style={{position:'absolute',top:0,left:0,width:'100%',height:'100%'}}
								title="How To Setup Your Camera For Analyzing Your Swing"/>
						</div>
					</Col>
				</Row>
			<div className="mb-3 p-4">
				<Card className="p-3">
				<ul className="mb-0">
					<li>Record a swing face on and down the line</li>
					<li>Hitting a ball is preferred but <b>NOT</b> required</li>
					<li>Keep it simple, short and under 30 seconds.</li>
				</ul>
				</Card>
			</div>


				<label>Current Handicap (0-40) - update if changed</label>
				<input type="number" id="handicap" min={0} max={40} required name="handicap" defaultValue={user.handicap || 0} className="form-control mb-4"/>
				<label>Notes for Instructor (optional)</label>
				<textarea className="form-control mb-4" name="notes" id="notes" rows={3} placeholder="Give us some notes about the swings you’re submitting."/>
			<div ref={ref} className={className} style={style}>
			{previews}

			{files.length < maxFiles && input}

			{files.length > 0 && submitButton}
		</div>
			</Container>
			</>
	)
}

const VideoUpload = ( { redirectTo, consult = 0 , instructorId = 0}) => {
	const { setUser } = useAuth();
	const navigate = useNavigate();
	const [show, setShow] = useState(false);

	const handleClose = () => {
		setShow(false);
		if (redirectTo) {
			return navigate(redirectTo, { replace: true });
		}
		return navigate("/dna-profile", { replace: true });
	}
	const handleShow = () => setShow(true);

	const getUploadParams = async ({ meta: { name, type } }) => {
		const response = await API.post("reviews/upload-videos", { name, type });
		console.log(response.data)
		return { fields: response.data.fields, meta: { fileUrl: response.data.fileUrl }, url: response.data.uploadUrl }
	}

	const handleChangeStatus = ({ meta }, status) => {
		console.log(status, meta)
	}

	const handleSubmit = async (files, allFiles) => {
		const elem = document.getElementById('notes');
		const elemHandicap = document.getElementById('handicap');
		try {
			await API.post("reviews", { notes: elem.value, handicap: elemHandicap.value, files, consult, instructorId });
			allFiles.forEach(f => f.remove())
			elem.value = '';
			handleShow(); //modal

		} catch (e) {
			elem.value = '';
			allFiles.forEach(f => f.remove())
			notify(e.response.data.message);
		}

	}

	return (
		<>
		<Dropzone
			getUploadParams={getUploadParams}
			onChangeStatus={handleChangeStatus}
			LayoutComponent={NoDropzoneLayout}
			onSubmit={handleSubmit}
			//inputComponent={Input}
			inputContent="Click here to upload up to two videos"
			//styles={{ dropzone: { minHeight: 200, maxHeight: 250 } }}
			accept="video/*"
			autoUpload={true}
			maxFiles={2}

		/>
	<Modal
		show={show}
		onHide={handleClose}
		backdrop="static"
		keyboard={false}
	>
		<Modal.Header>
			<Modal.Title>You're all set!</Modal.Title>
		</Modal.Header>
		<Modal.Body>
			Your virtual lesson has been submitted for review. We’ll get back to you as quickly as possible.<br/><br/>

			<b>Expected Response Time (If not sooner)</b><br/><br/>

			<b>Premier:</b> 48-72 Business Hours<br/><br/>

			<b>Premier+:</b> 36-48 Business Hours<br/><br/>

			<b>PRO:M</b> 24 hours or less
		</Modal.Body>
		<Modal.Footer>
			<Button variant="primary" onClick={handleClose}>Understood</Button>
		</Modal.Footer>
	</Modal>
			</>
	)
}

export default VideoUpload;

import {useState, useEffect} from "react";
import API from "../api";
import {SwiperVideos} from "./SwiperVideos";
import Spinner from "react-bootstrap/Spinner";

function NewReleases() {

	const [videos, setVideos] = useState([])
	const [isLoading, setLoading] = useState(false)

	useEffect(() => {
		setLoading(true);
		API.get(`video?category=latest`)
			.then(data => setVideos(data.data))
			.finally(() => setLoading(false));
	}, []);

	return (
			isLoading ?
				<div className="p-4" style={{textAlign: 'center'}}><Spinner className="text-orange" animation="border"/></div> :
				<>
					<SwiperVideos
						videos={videos}
					/>
				</>
	);
}

export default NewReleases;

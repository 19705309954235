import {Button} from "react-bootstrap";
import {useAuth} from "../../AuthUser";
import {useState} from "react";
import API from "../../api";
import {useParams} from "react-router-dom";
import moment from "moment";
import {Alert} from "react-bootstrap";
import ProfileImage from "../ProfileImage";


function CommentForm() {
	const [result, setResult] = useState();
	const [disabled, setDisabled] = useState(false);
	const [isSubmitting, setSubmitting] = useState(false);
	const [text, setText] = useState('');

	const auth = useAuth();
	let { slug } = useParams();

	const noName = `user00${auth.user.id+ auth.user.id}`;
	const onChange = (e) => {
		setText(e.value);
	}

	const handleSubmit = (e) => {
		e.preventDefault();

		if (e.target.comment.value) {
			setSubmitting(true);
			API.post(`videoComments/${slug}`, {
				comment: e.target.comment.value,
				creator: auth.user.id,
			})
				.then(() => setResult(e.target.comment.value))
				.finally(() => {
					setSubmitting(false);
					setDisabled(true);
					setText('');
				}
		);
		}
	}

	const comment = (comments) => <div className="mt-5 comment-container">
		<div className='comments'>
			<div className="d-flex flex-row p-3 gap-3">
				{/*<img src={auth.user.thumbnail} width="40" height="40" className="rounded-circle mr-3" alt="user"/>*/}
				<ProfileImage size={40} textSizeRatio={2.75} text={auth.user.email}/>
				<div className="w-100">
					<div className="d-flex justify-content-between align-items-center">
						<div className="d-flex flex-row align-items-center">
							<span className="me-2">{auth.user?.firstName || noName}</span>
							<small className="text-orange"><strong>{auth.user.admin ? 'Master Instructor' : ''}</strong></small>
						</div>
						<small>{moment().fromNow()}</small>
					</div>
					<p className="text-justify comment-text mb-0">{comments}</p>
				</div>
			</div>
		</div>
	</div>

	return (auth.user.planType ?
		<>
			<form className="mb-5" onSubmit={handleSubmit}>
			<div className="mt-3 d-flex flex-row align-items-center gap-3">
				<textarea name="comment" value={text} onChange={onChange} className="form-control" placeholder="Enter your comment..." disabled={disabled}/>
			</div>
			<div className="mt-2 float-end">
				<Button size="sm" variant="primary" type="submit" disabled={disabled}>
					{isSubmitting ? (
						<>
													<span
														className="spinner-border spinner-border-sm"
														role="status"
														aria-hidden={true}
													/>{" "}
						</>
					) : (
						"Comment"
					)}
				</Button>
			</div>
			</form>
			{result ? comment(result) : ''}
		</> : <Alert variant="danger">You must be a premium member to comment. <a href="/pricing">GET ACCESS NOW</a></Alert>
	)
}

export default CommentForm;

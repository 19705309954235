import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {
	Button,
	FormGroup,
	Form,
	Row,
	Col,
	Stack
} from "react-bootstrap";
import { Formik } from "formik";
import { toast } from "react-toastify";
import API from "../../api";
import {Helmet} from "react-helmet";
import moment from "moment";

const EditVideo = () => {
	const navigate = useNavigate();

	const [categories, setCategories] = useState([])
	const [isLoading, setLoading] = useState(false)

	const notify = (message) =>
		toast.error(message, {
			position: toast.POSITION.TOP_RIGHT,
		});

	const success = (message) =>
		toast.success(message, {
			position: toast.POSITION.TOP_RIGHT,
		});

	useEffect(() => {
		setLoading(true);
		API.get(`admin/categories`)
			.then(response => setCategories(response.data))
			.finally(() => setLoading(false));
	}, [])

	function createSelectItems() {
		return categories.map((category,index) => {
			return <option key={index} value={category.id}>{category.title}</option>
		})
	}

	return (
		<Formik
			enableReinitialize={true}
			initialValues={{
				title: "",
				vimeoId: "",
				description: "",
				free: true,
				active: true,
				releaseOnDate: moment(new Date()).format('YYYY-MM-DD'),
				categoryId: 1,
				orderBy: 0,
			}}
			validate={(values) => {
				const errors = {};
				if (!values.title) {
					errors.title = "Required";
				}
				if (!values.vimeoId) {
					errors.vimeoId = "Required";
				}
				if (!values.description) {
					errors.description = "Required";
				}
				if (!values.releaseOnDate) {
					errors.releaseOnDate = "Required";
				}
				if (!values.free) {
					errors.free = "Required";
				}
				if (!values.active) {
					errors.active = "Required";
				}
				if (!values.categoryId) {
					errors.categoryId = "Required";
				}

				return errors;
			}}
			onSubmit={(values, { setSubmitting, setErrors }) => {
				setTimeout(() => {
					API.post('admin/video/create', {
						title: values.title,
						vimeoId: values.vimeoId,
						description: values.description,
						thumbnail: values.thumbnail,
						free: values.free,
						active: values.active,
						releaseOnDate: values.releaseOnDate,
						categoryId: values.categoryId,
						orderBy: values.orderBy,
					}, {
						headers: {
							"Content-Type": "multipart/form-data"
						}
					})
						.then((res) => {
							setSubmitting(false);
							success('Successfully created video');
							navigate('/admin/videos');
						})
						.catch((error) => {
							setSubmitting(false);
							if (error.response) {
								notify(error.response.data.message);
							}
							if (
								error.response &&
								error.response.data.errors &&
								error.response.data.errors.length
							) {
								const result = error.response.data.errors.reduce(function (
										map,
										obj
									) {
										map[obj.property] = obj.message;
										return map;
									},
									{});
								setErrors(result);
							}
						});
				}, 400);
			}}
		>
			{({
					values,
					errors,
					touched,
					handleChange,
					handleSubmit,
					isSubmitting,
					setFieldValue,
				}) => (
				<>
					<Helmet>
						<title>Create Video | MyGolfDNA.com</title>
						<meta name="description" content="create video for website"/>
					</Helmet>
					<Row>
						<Col md={8}>

							<Form noValidate className="mt-5 form-group" onSubmit={handleSubmit}>
								<h3>Create Video</h3>
								<Row>
									<Col md={12}>
										<Form.Group className="form-floating mb-3">
											<Form.Control
												className=""
												placeholder="Title"
												type="text"
												name="title"
												id="title"
												onChange={handleChange}
												value={values.title}
												isInvalid={errors.title && touched.title}
												isValid={!errors.title && touched.title}
											/>
											<Form.Label className="" htmlFor="title">Title</Form.Label>
											<div className="invalid-feedback">{errors.title}</div>
										</Form.Group>
									</Col>
									<Col md={3}>
										<Form.Group className="form-floating mb-3">
											<Form.Control
												className=""
												placeholder="Vimeo Id"
												type="text"
												name="vimeoId"
												id="vimeoId"
												onChange={handleChange}
												value={values.vimeoId}
												isInvalid={errors.vimeoId && touched.vimeoId}
												isValid={!errors.vimeoId && touched.vimeoId}
											/>
											<Form.Label className="" htmlFor="vimeId">Vimeo Id</Form.Label>
											<div className="invalid-feedback">{errors.vimeoId}</div>
										</Form.Group>
									</Col>
								</Row>
								<Form.Group className="mb-3">
									<Form.Label className="" htmlFor="category">Category</Form.Label>
									<Form.Select
										aria-label="category"
										name="categoryId"
										id="category"
										onChange={handleChange}
										value={values.categoryId}
										isInvalid={errors.categoryId && touched.categoryId}
										isValid={!errors.categoryId && touched.categoryId}
									>
										{createSelectItems()}
									</Form.Select>
								</Form.Group>
								<Form.Group className="mb-3">
									<Form.Control
										placeholder="description of video."
										as="textarea"
										rows="5"
										name="description"
										id="description"
										onChange={handleChange}
										value={values.description}
										isInvalid={errors.description && touched.description}
										isValid={!errors.description && touched.description}
									/>
									<div className="invalid-feedback">{errors.description}</div>
								</Form.Group>
								<Form.Group className="mb-3">
									<Form.Label className="" htmlFor="thumbnail">Thumbnail</Form.Label>
									<Form.Control
										className=""
										type="file"
										name="thumbnail"
										id="thumbnail"
										onChange={(event) => {
											const files = event.target.files[0];
											setFieldValue("thumbnail", files);
										}}
										isInvalid={errors.thumbnail && touched.thumbnail}
										isValid={!errors.thumbnail && touched.thumbnail}
									/>
									<div className="invalid-feedback">{errors.thumbnail}</div>
								</Form.Group>
								<Form.Group className="mb-3">
									<Form.Label className="" htmlFor="free">Free or Premium</Form.Label>
									<Form.Select
										aria-label="free"
										name="free"
										id="free"
										onChange={handleChange}
										value={values.free}
										isInvalid={errors.free && touched.free}
										isValid={!errors.free && touched.free}
									>
										<option value="false">Premium</option>
										<option value="true">Free</option>
									</Form.Select>
								</Form.Group>
								<Form.Group className="mb-3">
									<Form.Label className="" htmlFor="active">Status</Form.Label>
									<Form.Select
										aria-label="active"
										name="active"
										id="active"
										onChange={handleChange}
										value={values.active}
										isInvalid={errors.active && touched.active}
										isValid={!errors.active && touched.active}
									>
										<option value="false">Disabled</option>
										<option value="true">Active</option>
									</Form.Select>
								</Form.Group>
								<Form.Group className="form-floating mb-3">
									<Form.Control
										className=""
										placeholder="mm/dd/yyyy"
										type="date"
										name="releaseOnDate"
										id="releaseOnDate"
										onChange={handleChange}
										value={values.releaseOnDate}
										isInvalid={errors.releaseOnDate && touched.releaseOnDate}
										isValid={!errors.releaseOnDate && touched.releaseOnDate}
									/>
									<Form.Label className="" htmlFor="releaseOnDate">Release On Date</Form.Label>
									<div className="invalid-feedback">{errors.releaseOnDate}</div>
								</Form.Group>
								<Col md={3}>
									<Form.Group className="form-floating mb-3">
										<Form.Control
											className=""
											placeholder="Order By"
											type="number"
											name="orderBy"
											id="orderBy"
											defaultValue={0}
											onChange={handleChange}
											value={values.orderBy}
											isInvalid={errors.orderBy && touched.orderBy}
											isValid={!errors.orderBy && touched.orderBy}
										/>
										<Form.Label className="" htmlFor="title">Order By</Form.Label>
										<div className="invalid-feedback">{errors.orderBy}</div>
									</Form.Group>
								</Col>
								<FormGroup className="mt-5">
									<Stack direction="horizontal" gap={3}>
										<Button variant="primary" size="lg" type="submit" disabled={isSubmitting}>
											{isSubmitting ? (
												<>
													<span
														className="spinner-border spinner-border-sm"
														role="status"
														aria-hidden={true}
													/>{" "}
												</>
											) : (
												"Submit"
											)}
										</Button>
									</Stack>
								</FormGroup>
							</Form>
						</Col>
					</Row>
				</>
			)}
		</Formik>
	);
};

export default EditVideo;

import * as React from "react";
import {useAuth} from "../AuthUser";
import {Chart} from "primereact/chart";
import {useEffect, useState} from "react";
import API from "../api";

function ProgressTracker() {
	let auth = useAuth();
	const [chartData, setChartData] = useState({});
	const [chartOptions, setChartOptions] = useState({});
	const [isLoading, setLoading] = useState(false)
	const [key, setKey] = useState('home');
	const defaultFree = [
		{
			"x": "2023-07-13T16:23:21.000Z",
			"y": 30
		},
		{
			"x": "2023-07-13T16:38:37.000Z",
			"y": 24
		},
		{
			"x": "2023-07-13T16:39:16.000Z",
			"y": 20
		},
		{
			"x": "2023-08-25T00:02:02.000Z",
			"y": 17
		},
		{
			"x": "2023-09-08T08:08:12.000Z",
			"y": 8
		},
		{
			"x": "2023-09-14T05:31:49.000Z",
			"y": 5
		}
	]
	useEffect(() => {
		const documentStyle = getComputedStyle(document.documentElement);
		const textColor = documentStyle.getPropertyValue('--text-color');
		const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary');
		const surfaceBorder = documentStyle.getPropertyValue('--surface-border');

		setLoading(true);
		API.get("handicap/all")
			.then(response => {
				const data = {
					//labels: [],
					datasets: [
						{
							label: 'Handicap',
							fill: false,
							borderColor: documentStyle.getPropertyValue('--orange-500'),
							backgroundColor: documentStyle.getPropertyValue('--orange-500'),
							yAxisID: 'y',
							tension: 0.4,
							data: auth.user.planType ? response.data : defaultFree
						}
					]
				};
				const options = {
					responsive: true,
					stacked: false,
					maintainAspectRatio: false,
					aspectRatio: 0.6,
					scales: {
						x: {
							beginAtZero: true,
							ticks: {
								display: false,
							}
						},
						y: {
							display: true,
							title: {
								display: false,
								text: 'Handicap'
							},
							min: 0,
							max: 40,
						},
					},
					plugins: [{
						legend: {
							labels: {
								color: textColor
							}
						},
					}]
				};

				setChartData(data);
				setChartOptions(options);
			})
			.finally(() => setLoading(false));

	}, []);
	return (
		<>
			<Chart type="line" className="chart-progress-tracker" data={chartData} options={chartOptions} />
		</>
	);
}

export default ProgressTracker;

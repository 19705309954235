import {useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import API from "../../api";
import LoadingDiv from "../LoadingDiv";
import Comments from "./Comments";

function LessonComment() {
	const [lessonComments, setLessonComments] = useState([])
	const [isLoading, setLoading] = useState(false)
	let { lessonId } = useParams();

	useEffect(() => {
		setLoading(true);
		API.get(`lessonComments/${lessonId}`)
			.then(response => setLessonComments(response.data))
			.finally(() => setLoading(false));
	}, [lessonId])

	return (
		<>
			<div id="comments-container" className="comment-container">
				{isLoading ? <LoadingDiv/> : <Comments comments={lessonComments}/>}
			</div>
		</>
	)
}

export default LessonComment;

import { Container, Row, Col } from 'react-bootstrap';
import TerryCraft from '../assets/img/testimonials/terry-craft.png';
import Rob from '../assets/img/testimonials/rob-testimonial.png';
import Shane from '../assets/img/testimonials/shane.png';

function HomeCarousel() {
	return (
		<>
		<Container>
			<Row className="justify-content-center text-center">
				<Col>
					<img
						className="rounded-circle mb-4"
						src={Shane}
						alt="Shane"
						height={140}
					/>
					<figure className="text-center">
						<blockquote className="blockquote">
							<p>Thanks again for the time today. I enjoyed learning more about the swing. It was a solid start and I look forward to building on these fundamentals.</p>
						</blockquote>
						<figcaption className="blockquote-footer">
							Shane, <cite title="Source Title">Florida </cite>
						</figcaption>
					</figure>
				</Col>
				<Col>

					<img
						className="rounded-circle mb-4"
						src={TerryCraft}
						alt="Terry Craft"
						width={140}
					/>
					<figure className="text-center">
						<blockquote className="blockquote">
							<p>I can't thank you enough Chris! I have a tremendous sense of accomplishment and confidence going forward after your coaching/training/instruction.
								You are a true Rock Star in Golf,  And, I might add, a damn nice guy as well!</p>
						</blockquote>
						<figcaption className="blockquote-footer">
							Terry, <cite title="Source Title">New York</cite>
						</figcaption>
					</figure>
				</Col>
				<Col>
					<img
						className="rounded-circle mb-4"
						src={Rob}
						alt="Rob"
						width={140}
					/>
					<figure className="text-center">
						<blockquote className="blockquote">
							<p>This year I went from 19.9 to 13.0. I honestly feel like I've crossed another threshold.
								My swing looks very smooth and has good tempo and when I flush the ball, I'm crushing it. It really feels so much better for my back too.</p>
						</blockquote>
						<figcaption className="blockquote-footer">
							Rob, <cite title="Source Title">Florida</cite>
						</figcaption>
					</figure>
					<p></p>
					<p className="small text-white-50 fst-italic"></p>
				</Col>
			</Row>
		</Container>
		</>
	)
}

export default HomeCarousel;

import React from "react";
import {Link, Navigate, useLocation, useNavigate} from "react-router-dom";
import {
	Button,
	FormGroup,
	Form,
	Container,
	Row,
	Col,
} from "react-bootstrap";
import { Formik } from "formik";
import { ToastContainer, toast } from "react-toastify";
import { useAuth } from "../../AuthUser";
import API from "../../api";
import {Helmet} from "react-helmet";

function useQuery() {
	const { search } = useLocation();

	return React.useMemo(() => new URLSearchParams(search), [search]);
}
const ResetPassword = () => {
	const { token } = useAuth();
	const navigate = useNavigate();
	let query = useQuery();

	if (token) {
		return <Navigate to="/account" replace />;
	}

	if (!query.get('token')) {
		return <Navigate to="/" replace />;
	}

	const notifySuccess = (message) =>
		toast.success(message, {
			position: toast.POSITION.TOP_RIGHT,
		});

	const notifyError = (message) =>
		toast.error(message, {
			position: toast.POSITION.TOP_RIGHT,
		});

	return (
		<Formik
			initialValues={{ confirmPassword: "", password: "", token: null}}
			validate={(values) => {
				const errors = {};
				if (!values.password) {
					errors.password = "Required";
				}
				if (!values.confirmPassword) {
					errors.confirmPassword = "Passwords must match";
				}
				if (values.password && values.confirmPassword) {
					if (values.password !== values.confirmPassword) {
						errors.confirmPassword = "Passwords must match";
						errors.password = "Passwords must match";
					}
				}

				return errors;
			}}
			onSubmit={(values, { setSubmitting, setErrors }) => {
				setTimeout(() => {
					API.post('auth/reset-password', {
						token: query.get('token'),
						password: values.password,
						confirmPassword: values.confirmPassword,
					})
						.then((res) => {
							navigate('/signin', { replace: true });
							notifySuccess("password successfully reset");
							setSubmitting(false);
						})
						.catch((error) => {
							setSubmitting(false);
							if (error.response) {
								notifyError(error.response.data.message);
							}
							if (
								error.response &&
								error.response.data.errors &&
								error.response.data.errors.length
							) {
								const result = error.response.data.errors.reduce(function (
										map,
										obj
									) {
										map[obj.property] = obj.message;
										return map;
									},
									{});
								setErrors(result);
							}
						});
				}, 400);
			}}
		>
			{({
					values,
					errors,
					touched,
					handleChange,
					handleSubmit,
					isSubmitting,
				}) => (
				<>
					<Helmet>
						<title>Reset Password | MyGolfDNA.com</title>
						<meta name="description" content="reset your password"/>
					</Helmet>
					<Container>
						<Row>
							<Col sm={12} md={{ span: 6, offset: 3 }} lg={{ span: 6, offset: 3 }} xl={{ span: 4, offset: 4 }}>
								<h2 className="text-white text-center mt-5">Reset Password</h2>
								<Form noValidate className="mt-5 form-group" onSubmit={handleSubmit}>
									<Form.Group className="form-floating mb-3">
										<Form.Control
											className="bg-dark border-light border-opacity-25 text-light"
											placeholder="new password"
											type="text"
											name="password"
											id="password"
											onChange={handleChange}
											value={values.password}
											isInvalid={errors.password && touched.password}
											isValid={!errors.password && touched.password}
										/>
										<Form.Label className="text-white" htmlFor="password">New Password</Form.Label>
										<div className="invalid-feedback">{errors.password}</div>
									</Form.Group>

									<Form.Group className="form-floating mb-3">
										<Form.Control
											className="bg-dark border-light border-opacity-25 text-light"
											placeholder="confirm new password"
											type="text"
											name="confirmPassword"
											id="confirmPassword"
											onChange={handleChange}
											value={values.confirmPassword}
											isInvalid={errors.confirmPassword && touched.confirmPassword}
											isValid={!errors.confirmPassword && touched.confirmPassword}
										/>
										<Form.Label className="text-white" htmlFor="confirmPassword">Confirm New Password</Form.Label>
										<div className="invalid-feedback">{errors.confirmPassword}</div>
									</Form.Group>
									<FormGroup className="mt-5">
										<div className="d-grid gap-4">
											<Button variant="secondary" size="lg" type="submit" disabled={isSubmitting}>
												{isSubmitting ? (
													<>
													<span
														className="spinner-border spinner-border-sm"
														role="status"
														aria-hidden={true}
													/>{" "}
													</>
												) : (
													"Update Password"
												)}
											</Button>
										</div>
									</FormGroup>
								</Form>
							</Col>
						</Row>
						<ToastContainer />
					</Container>
				</>
			)}
		</Formik>
	);
};

export default ResetPassword;

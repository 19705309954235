import * as React from "react";
import {useAuth} from "../../AuthUser";
import {Link, Navigate, useNavigate} from "react-router-dom";
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Badge from 'react-bootstrap/Badge';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Container from 'react-bootstrap/Container';
import {Helmet} from "react-helmet";
import Button from "react-bootstrap/Button";
import Details from "./Details";
import Security from "./Security";
import Subscription from "./Subscription";

function Account() {
	let auth = useAuth();
	const navigate = useNavigate();
	const firstName = !auth.user.firstName ? '' : auth.user.firstName;
	const lastName = !auth.user.lastName ? '' : auth.user.lastName;
	const fullName = `${firstName} ${lastName}`;

	if(!auth.user) {
		return <Navigate to="/signin" replace/>
	}

	return (
		<>
			<Helmet>
				<title>Account | mygolfdna.com</title>
				<meta name="description" content="edit account details"/>
			</Helmet>
			<div className="mt-5">
				<Container>
					<Row>
						<Col sm={12} md={{ span: 6, offset: 3 }} lg={{ span: 6, offset: 3 }} xl={{ span: 8, offset: 2 }}>
							<h3>Account Details</h3>
							<hr/>
							<div className="mt-4 vstack gap-4">
							<Details/>
							<Security/>
								<Subscription/>
						</div>
						</Col>
					</Row>
				</Container>
			</div>
		</>
	);
}

export default Account;

import React from "react";
import {
	Button,
	FormGroup,
	Form,
	Row,
	Col,
	Card,
	Container,
} from "react-bootstrap";
import { Formik } from "formik";
import { ToastContainer, toast } from "react-toastify";
import moment from "moment";
import { useAuth } from "../../AuthUser";
import API from "../../api";
import {Helmet} from "react-helmet";
import VideoUpload from "../VideoUpload";
import {useNavigate} from "react-router-dom";

const Consult = () => {
	const { setUser, user } = useAuth();
	const navigate = useNavigate();

	const notify = (message) =>
		toast.error(message, {
			position: toast.POSITION.TOP_RIGHT,
		});

	function isValidDate(s) {
		// Assumes s is "mm/dd/yyyy"
		return moment(s, 'YYYY-MM-DD',true).isValid()

	}


	return (
		<>
			<Helmet>
				<title>Build Your DNA Profile | mygolfdna.com</title>
				<meta name="description" content="build your dna profile"/>
			</Helmet>
			<div className="mt-4">
				<Container>
					<Row>
						<div className="text-center h1 text-uppercase monument-font">Build Your DNA Profile</div>
						<Col sm={12} md={{ span: 8, offset: 2 }} lg={{ span: 6, offset: 3 }} xl={{ span: 6, offset: 3 }}>
							<p className="text-center text-black-50">Take five minutes and tell us about you and your game, show us some swings and setup your consult. Piece of cake!</p>
							<div className="container d-flex justify-content-center align-items-center">

								<div className="progresses">
									<div className="steps bg-dark-blue">
										<span className="font-weight-bold"><i className="bi bi-check"/></span>
									</div>
									<div className="small d-none d-sm-block">About</div>


									<span className="line bg-dark-blue"/>

									<div className="steps bg-dark-blue">
										<span className="font-weight-bold"><i className="bi bi-check"/></span>
									</div>
									<div className="small d-none d-sm-block">Upload</div>
									<span className="line bg-dark-blue"/>

									<div className="steps bg-dark-blue">
										<span className="font-weight-bold"><i className="bi bi-check"/></span>
									</div>
									<div className="small d-none d-sm-block">Consult</div>

								</div>

							</div>
							<div className="mt-3 d-grid gap-3 text-center">
								<i className="bi bi-check-circle text-success d-none d-md-block" style={{fontSize: "5rem"}}/>
								<i className="bi bi-check-circle text-success d-md-none" style={{fontSize: "3rem"}}/>
								<p className="text-center">
									Time for the fun part...scheduling your live consult. During your consult, we will discuss the current state
									of your game, identify realistic goals and start laying out <span className="font-weight-bold text-decoration-underline">YOUR</span> plan of attack.
								</p>
								<div className="text-center">
									<Button variant="secondary" size="lg" href="https://meetings.hubspot.com/chris3535" className="text-white font-weight-bold">Book Consult Now</Button>
								</div>
							</div>


						</Col>
					</Row>
				</Container>
			</div>
		</>
	);
};

export default Consult;

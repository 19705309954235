import {useState} from "react";
import {Button, Offcanvas} from "react-bootstrap";
import {useAuth} from "../../AuthUser";
import {useParams} from "react-router-dom";
import moment from "moment";
import API from "../../api";
import ProfileImage from "../ProfileImage";
import { toast } from "react-toastify";

function Comment(comment) {
	const [show, setShow] = useState(false);
	const [deleted, setDeleted] = useState(false);
	const handleClose = () => setShow(false);
	const handleShow = (e) => {
		e.preventDefault()
		setShow(true);
	}

	const success = (message) =>
		toast.success(message, {
			position: toast.POSITION.TOP_RIGHT,
		});

	const handleDelete = async (e,commentId) => {
		e.preventDefault()
		if(window.confirm("Are you sure you want to delete this comment?")) {
			await API.delete(`lessonComments/${commentId}`);
			success("Successfully deleted comment");
			setDeleted(true);
		}
	}

	const [result, setResult] = useState();
	const [disabled, setDisabled] = useState(false);
	const [isSubmitting, setSubmitting] = useState(false);
	const [text, setText] = useState('');

	const auth = useAuth();
	let { lessonId } = useParams();
	comment = comment.comment;

	const noName = `user00${auth.user.id+ auth.user.id}`;

	const onChange = (e) => {
		setText(e.value);
	}

	const handleSubmit = (e) => {
		e.preventDefault();
		if (e.target.comment.value) {
			setSubmitting(true);
			API.post(`lessonComments/${lessonId}`, {
				comment: e.target.comment.value,
				creator: auth.user.id,
				parentId: e.target.parentId.value,
			})
				.then(() => setResult(e.target.comment.value))
				.finally(() => {
						setSubmitting(false);
						setDisabled(true)
						handleClose()
						setText('');
					}
				);
		}
	}

	const reply = (comments) => <div className="comment-container">
		<div className='comments'>
			<div className="d-flex flex-row mt-3 gap-3">
				<img src={auth.user.thumbnail} width="40" height="40" className="rounded-circle mr-3" alt="user"/>
				<div className="w-100">
					<div className="d-flex justify-content-between align-items-center">
						<div className="d-flex flex-row align-items-center">
							<span className="me-2">{auth.user?.firstName || noName}</span>
							<small className="text-orange"><strong>{auth.user.admin ? 'Master Instructor' : ''}</strong></small>
						</div>
						<small>{moment().fromNow()}</small>
					</div>
					<p className="text-justify comment-text mb-0">{comments}</p>
				</div>
			</div>
		</div>
	</div>
	return (
		<div className={deleted ? 'visually-hidden' : ''} key={comment.id}>
			<div className={`comments ${comment.parentId ? 'ms-3' : ''}`}>
				<div className="d-flex flex-row p-3 gap-3">
					<ProfileImage size={40} textSizeRatio={2.75} text={comment.creator.email}/>
					<div className="w-100">
						<div className="d-flex justify-content-between align-items-center">
							<div className="d-flex flex-row align-items-center">
								<span className="me-2">{comment.creator?.firstName || noName} {auth.user.admin ? comment.creator?.lastName || '' : ''}</span>
								<small className="text-orange"><strong>{comment.creator.admin ? 'Master Instructor' : ''}</strong></small>
							</div>
							<small>{moment(comment.created_at).fromNow()}</small>
						</div>
						<p className="text-justify comment-text mb-0">{comment.comment}</p>
						<div className="d-flex flex-row gap-1">
							{auth.user.planType ?
								<a href="#" className="ms-3 small text-decoration-none"
									 onClick={handleShow}><strong>Reply</strong></a>
								: ''}
							{auth.user.admin || auth.user.id === comment.creator.id ?
								<a href="#" className="ms-3 small text-decoration-none"
									 onClick={(e) =>
										 handleDelete(e,comment.id)}><strong>Delete</strong></a>
								: ''}
						</div>
						{result ? reply(result) : ''}
					</div>
				</div>
			</div>
			<Offcanvas className="offcanvas-backdrop-blue" show={show} onHide={handleClose} placement="bottom" backdrop="static" scroll={true}>
				<Offcanvas.Header closeButton>
					<Offcanvas.Title/>
				</Offcanvas.Header>
				<Offcanvas.Body>
					<p className="text-justify comment-text">{comment.comment}</p>
					<form className="" onSubmit={handleSubmit}>
						<div className="d-flex flex-row align-items-center gap-3">
							<textarea name="comment" value={text} onChange={onChange} className="form-control" placeholder="Enter your comment..." disabled={disabled}/>
							<input type="hidden" name="parentId" value={comment.id}/>
						</div>
						<div className="mt-2 float-end">
							<Button size="sm" variant="primary" type="submit" disabled={disabled}>
								{isSubmitting ? (
									<>
													<span
														className="spinner-border spinner-border-sm"
														role="status"
														aria-hidden={true}
													/>{" "}
									</>
								) : (
									"Comment"
								)}
							</Button>
						</div>
					</form>
				</Offcanvas.Body>
			</Offcanvas>
		</div>
	)
}

export default Comment;

import React from "react";
import {
	Button,
	FormGroup,
	Form,
	Row,
	Col,
	Card,
	Container,
} from "react-bootstrap";
import { Formik } from "formik";
import { ToastContainer, toast } from "react-toastify";
import moment from "moment";
import { useAuth } from "../../AuthUser";
import API from "../../api";
import {Helmet} from "react-helmet";
import VideoUpload from "../VideoUpload";
import {useNavigate} from "react-router-dom";

const Cancel = () => {
	const { setUser, user } = useAuth();
	const navigate = useNavigate();

	const notify = (message) =>
		toast.success(message, {
			position: toast.POSITION.TOP_RIGHT,
		});

	function isValidDate(s) {
		// Assumes s is "mm/dd/yyyy"
		return moment(s, 'YYYY-MM-DD',true).isValid()

	}
	const cancelSubscription = async () => {
		if(window.confirm("Are you sure you want to cancel your subscription?")) {
			await API.get('pay/subscriptions/cancel');
			notify("Successfully cancelled subscription");
			navigate('/dna-profile');
		}
	}


	return (
		<>
			<Helmet>
				<title>Cancel Subscription | MyGolfDNA.com</title>
				<meta name="description" content="build your dna profile"/>
			</Helmet>
			<div className="mt-4">
				<Container>

					<Row>
						<div className="text-center h1 text-uppercase monument-font">Cancel Subscription</div>
						<Col sm={12} md={{ span: 8, offset: 2 }} lg={{ span: 6, offset: 3 }} xl={{ span: 6, offset: 3 }}>
							<div className="mt-3 d-grid gap-3 text-center">
								<p className="text-center">
									We are sorry to see you go. Please email support@mygolfdna.com to cancel your subscription.
								</p>
								<p className="text-center visually-hidden">
									Downgrade to video only for $9.99/month and upgrade again whenever you are ready.
								</p>
								<div className="text-center visually-hidden">
									<Button variant="secondary" size="lg" href="/basic" className="text-white font-weight-bold visually-hidden">Downgrade to Video only for $9.99/month</Button>
								<p><a href="#" onClick={()=>cancelSubscription()}>Cancel Subscription</a> </p>
								</div>
							</div>


						</Col>
					</Row>
				</Container>
			</div>
		</>
	);
};

export default Cancel;

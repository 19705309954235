import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

import './assets/scss/custom.scss';
import "react-toastify/dist/ReactToastify.min.css";
//theme
import "primereact/resources/themes/lara-light-indigo/theme.css";

//core
import "primereact/resources/primereact.min.css";

import App from "./App";
import {ToastContainer} from "react-toastify";

ReactDOM.createRoot(document.getElementById("root")).render(
  <BrowserRouter>
      <App />
      <ToastContainer/>
    </BrowserRouter>
);

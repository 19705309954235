import * as React from "react";
import { Outlet } from "react-router-dom";
import EmptyNavigation from "./../components/Navigations/EmptyNavigation";
import {Container, Row, Col} from "react-bootstrap";
import Footer from "../components/Footer";

function SubscriptionLayout({ backgroundColor = "#fff"}) {
	return (
		<div style={{backgroundColor, minHeight: "100vh"}} className="mb-4">
			<EmptyNavigation hideAuthButton={false} />
			<Outlet/>
		</div>
	);
}

export default SubscriptionLayout;

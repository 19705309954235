import * as React from "react";
import { Outlet } from "react-router-dom";
import LoggedInNavigation from "./../components/Navigations/LoggedInNavigation";
import {Container, Row, Col} from "react-bootstrap";
import Footer from "../components/Footer";
import AdminNavigation from "../components/Navigations/AdminNavigation";

function AdminLayout({ backgroundColor = "#fff"}) {
	return (
		<>
			<LoggedInNavigation />
			<AdminNavigation/>
			<div className="content">
				<Outlet/>
			</div>
			</>
	);
}

export default AdminLayout;

import Avatar from "react-avatar";

function ProfileImage ({ className, text, size, textSizeRatio }) {
	return (
		<>
			<Avatar size={size} textSizeRatio={textSizeRatio} round={true} className={className} name={text} />
		</>
	)

}

export default ProfileImage;

import React from "react";
import {
	Button,
	FormGroup,
	Form,
	Row,
	Col,
	Card,
} from "react-bootstrap";
import { Formik } from "formik";
import { ToastContainer, toast } from "react-toastify";
import API from "../../api";

const Security = () => {

	const notify = (message) =>
		toast.error(message, {
			position: toast.POSITION.TOP_RIGHT,
		});

	const getData = () => ({ password: "", confirmPassword: "" });

	return (
		<Formik
			enableReinitialize={true}
			initialValues={getData()}

			validate={(values) => {
				const errors = {};
				if (!values.password) {
					errors.password = "Required";
				}
				if (!values.confirmPassword) {
					errors.confirmPassword = "Passwords must match";
				}
				if (values.password && values.confirmPassword) {
					if (values.password !== values.confirmPassword) {
						errors.confirmPassword = "Passwords must match";
						errors.password = "Passwords must match";
					}
				}

				return errors;
			}}
			onSubmit={(values, { setSubmitting, setErrors }) => {
				setTimeout(() => {
					API.put("accounts/me/update-password", {
						password: values.password,
						confirmPassword: values.confirmPassword,
					})
						.then((res) => {

							toast.success('Password updated successfully.', {
								position: toast.POSITION.TOP_RIGHT,
							});
							setSubmitting(false);
						})
						.catch((error) => {
							setSubmitting(false);
							if (error.response) {
								notify(error.response.data.message);
							}
							if (
								error.response &&
								error.response.data.errors &&
								error.response.data.errors.length
							) {
								const result = error.response.data.errors.reduce(function (
										map,
										obj
									) {
										map[obj.property] = obj.message;
										return map;
									},
									{});
								setErrors(result);
							}
						});
				}, 400);
			}}
		>
			{({
					values,
					errors,
					touched,
					handleChange,
					handleSubmit,
					isSubmitting,
					setFieldValue,
				}) => (
				<>
					<Card>
						<Card.Header as="h5">Update Your Password</Card.Header>
						<Card.Body>
							<Form noValidate className="form-group" onSubmit={handleSubmit}>
								<Row>
									<Col sm={12} md={6}>
										<Form.Group className="form-floating mb-3">
											<Form.Control
												className=""
												placeholder="Password"
												type="password"
												name="password"
												id="password"
												onChange={handleChange}
												value={values.password}
												isInvalid={errors.password && touched.password}
											/>
											<Form.Label className="" htmlFor="password">New password</Form.Label>
											<div className="invalid-feedback">{errors.password}</div>
										</Form.Group>
									</Col>
									<Col sm={12} md={6}>
										<Form.Group className="form-floating mb-3">
											<Form.Control
												className=""
												placeholder="Confirm password"
												type="password"
												name="confirmPassword"
												id="confirmpassword"
												onChange={handleChange}
												value={values.confirmPassword}
												isInvalid={errors.confirmPassword && touched.confirmPassword}
											/>
											<Form.Label className="" htmlFor="confirmpassword">Confirm new password</Form.Label>
											<div className="invalid-feedback">{errors.confirmPassword}</div>
										</Form.Group>
									</Col>
								</Row>
								<FormGroup className="">
									<Button variant="primary" size="lg" type="submit" disabled={isSubmitting}>
										{isSubmitting ? (
											<>
													<span
														className="spinner-border spinner-border-sm"
														role="status"
														aria-hidden={true}
													/>{" "}Saving changes
											</>
										) : (
											"Save changes"
										)}
									</Button>
								</FormGroup>
							</Form>
						</Card.Body>
					</Card>
				</>
			)}
		</Formik>
	);
};

export default Security;

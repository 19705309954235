import React from "react";
import {
	Button,
	Card,
	Table,
	Dropdown,
} from "react-bootstrap";
import { toast } from "react-toastify";
import API from "../../api";
import {useEffect, useState} from "react";
import {useAuth} from "../../AuthUser";
import Badge from "react-bootstrap/Badge";
import { NavLink } from "react-router-dom";
import moment from "moment";

const cadenceMap = {
	'MONTHLY': 'month',
	'ANNUAL': 'annual',
}

function insertDecimal(num) {
	return Number((num / 100).toFixed(2));
}


const Subscription = () => {
	const { user } = useAuth();
	const isSubmitting = false;
	const notify = (message) =>
		toast.error(message, {
			position: toast.POSITION.TOP_RIGHT,
		});

	const [subscription, setSubscription] = useState([])
	const [isLoading, setLoading] = useState(false)

	useEffect(() => {
		setLoading(true);
		API.get(`pay/subscriptions`)
			.then(data => setSubscription(data.data))
			.finally(() => setLoading(false));
	}, []);

	const statusTemplate = (status) => {
		if (status === 'active') {
			return <Badge bg="success">Active</Badge>
		}
		if (status === 'canceled') {
			return <Badge bg="danger">Cancelled</Badge>
		}
		return <Badge bg="warning">${status}</Badge>
	}

	return (
				<>
					<Card>
						<Card.Header as="h5">Manage Your Subscription</Card.Header>
						<Card.Body>
							<Card.Text>
								{subscription ? (
									<>
										<Table borderless>
											<thead style={{borderBottom: "1px solid #ccc"}}>
											<tr>
												<th>Plan</th>
												<th>Start Date</th>
												<th>Next Billing Date</th>
												<th>Status</th>
												<th></th>
											</tr>
											</thead>
											<tbody>
											<tr>
												<td>{user.planType}</td>
												<td>{moment(subscription.startDate).utc().format('LL')}</td>
												<td>{moment(subscription.endDate).utc().format('LL')}</td>
												<td>{statusTemplate(subscription.status)}</td>
												<td></td>
											</tr>
											</tbody>
										</Table>
										<Button className="" variant="success" size="sm" as={NavLink} to="/pricing?t=upgrade">
											Change Plan
										</Button>
										{" "}
										{subscription.canceledDate ? '' : <a className="small text-decoration-none" href="/cancel">Cancel Subscription</a>}<br/><br/>
										{subscription.canceledDate ? '' : <a className="small text-decoration-none" href="/update-payment">Update Payment Details</a>}
									</>
								) : <Button className="" variant="success" size="sm" as={NavLink} to="/pricing">
									Subscribe to Premium Membership
								</Button>}
							</Card.Text>

						</Card.Body>
					</Card>
				</>
	);
};

export default Subscription;

import * as React from "react";
import {Chart} from "primereact/chart";
import {useEffect, useState} from "react";

function ProgressTracker(handicaps) {
	const [chartData, setChartData] = useState({});
	const [chartOptions, setChartOptions] = useState({});

	useEffect(() => {
		console.log(handicaps)
		const documentStyle = getComputedStyle(document.documentElement);
		const textColor = documentStyle.getPropertyValue('--text-color');

		const data = {
			//labels: [],
			datasets: [
				{
					label: 'Handicap',
					fill: false,
					borderColor: documentStyle.getPropertyValue('--orange-500'),
					backgroundColor: documentStyle.getPropertyValue('--orange-500'),
					yAxisID: 'y',
					tension: 0.4,
					data: handicaps?.handicaps || []
				}
			]
		};
		const options = {
			responsive: true,
			stacked: false,
			maintainAspectRatio: false,
			aspectRatio: 0.6,
			scales: {
				x: {
					beginAtZero: true,
					ticks: {
						display: false,
					}
				},
				y: {
					display: true,
					title: {
						display: false,
						text: 'Handicap'
					},
					min: 0,
					max: 40,
				},
			},
			plugins: [{
				legend: {
					labels: {
						color: textColor
					}
				},
			}]
		};

		setChartData(data);
		setChartOptions(options);

	}, []);
	return (
		<>
			<Chart type="line" className="chart-progress-tracker" data={chartData} options={chartOptions} />
		</>
	);
}

export default ProgressTracker;

import React from "react";
import {
	Button,
	FormGroup,
	Form,
	Row,
	Col,
	Card,
	Container, Modal,
} from "react-bootstrap";
import { Formik } from "formik";
import { ToastContainer, toast } from "react-toastify";
import moment from "moment";
import { useAuth } from "../../AuthUser";
import API from "../../api";
import {Helmet} from "react-helmet";
import VideoUpload from "../VideoUpload";
import {useNavigate} from "react-router-dom";
import {CreditCard, PaymentForm} from "react-square-web-payments-sdk";
import api from "../../api";
import {useState} from "react";

const UpdatePayment = () => {
	const [show, setShow] = useState(false);
	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);
	const navigate = useNavigate();

	const notify = (message) =>
		toast.success(message, {
			position: toast.POSITION.TOP_RIGHT,
		});

	function isValidDate(s) {
		// Assumes s is "mm/dd/yyyy"
		return moment(s, 'YYYY-MM-DD',true).isValid()

	}
	const cancelSubscription = async () => {
		if(window.confirm("Are you sure you want to cancel your subscription?")) {
			await API.get('pay/subscriptions/cancel');
			notify("Successfully cancelled subscription");
			navigate('/dna-profile');
		}
	}


	return (
		<>
			<Helmet>
				<title>Update Payment | MyGolfDNA.com</title>
				<meta name="description" content="update payment details"/>
			</Helmet>
			<div className="mt-4">
				<Container>

					<Row>
						<Col sm={12} md={{ span: 8, offset: 2 }} lg={{ span: 6, offset: 3 }} xl={{ span: 6, offset: 3 }}>
							<div className="mt-3 d-grid gap-3 text-center">
								<h5 className="mb-3">Your Billing Details</h5>
								<p>Update your card on file for your current subscription</p>
								<PaymentForm
									applicationId="sq0idp--OAER2pSUlUDNqUV8uKXFA"
									cardTokenizeResponseReceived={async (token, verifiedBuyer) => {
										handleShow();
										await api.post("/pay/update", {
											sourceId: token.token,
										}).then(resp => {
											handleClose();
											notify('successfully updated payment details')
											navigate("/dna-profile", { replace: true });
										}).catch(err => {
											handleClose();
											notify(err.response.data.message);
										})
									}}
									locationId={process.env.NODE_ENV === 'production' ? "4F2E7F3QP3YD5" : "L36AG5ZKTWKGM"}
								>
									<CreditCard
										buttonProps={{
											css: {
												backgroundColor: "#157729",
												fontSize: "14px",
												color: "#fff",
												"&:hover": {
													backgroundColor: "#1f8c44",
												},
											},
										}}
									>
										Update Card
									</CreditCard>
								</PaymentForm>

							</div>


						</Col>
					</Row>
					<Modal
						show={show}
						onHide={handleClose}
						backdrop="static"
						keyboard={false}
					>
						<Modal.Body>
							<div className="text-center">
								<p>Updating Payment Details...</p>
								<div className="spinner-border" role="status">
									<span className="visually-hidden">Loading...</span>
								</div>
							</div>
						</Modal.Body>
					</Modal>
				</Container>

			</div>
		</>
	);
};

export default UpdatePayment;

import {Outlet} from "react-router-dom";
import * as React from "react";
import Navigation from "./../components/Navigation";
import {Col, Container, Row} from "react-bootstrap";
import Footer from "./../components/Footer";

function HomeLayout({ backgroundColor = "#fff"}) {
	return (
		<div style={{backgroundColor}}>
			<Navigation />
				<Outlet/>
			<Footer className="bg-dark-blue"/>
		</div>

);
}

export default HomeLayout;

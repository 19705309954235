import {useAuth} from "../AuthUser";
import {Navigate} from "react-router-dom";
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Badge from 'react-bootstrap/Badge';
import Container from 'react-bootstrap/Container';
import {Helmet} from "react-helmet";
import Button from "react-bootstrap/Button";
import API from "../api";
import {useEffect, useState} from "react";
import {Alert} from "react-bootstrap";
import moment from "moment";
import Spinner from 'react-bootstrap/Spinner';
import {Column} from "primereact/column";
import {DataTable} from "primereact/datatable";
import LoadingDiv from "../components/LoadingDiv";

function MyLessons() {
	let auth = useAuth();

	const canSubmitLesson = auth.user.canSubmitLesson;
	const lessonCountRemaining = auth.user.lessonCountRemaining || 0;
	const [lessons, setLessons] = useState([])
	const [isLoading, setLoading] = useState(false)

	useEffect(() => {
		setLoading(true);
		API.get("reviews")
			.then(response => setLessons(response.data))
			.finally(() => setLoading(false));
	}, [])

	const statusTemplate = (lesson) => {
		if (lesson.status === "complete") {
			return <Badge bg="success">Completed</Badge>
		}
			return <Badge bg="danger">Pending</Badge>
	}

	const updatedOnDateTemplate = (lesson) => {
		if(lesson.status === "pending") {
			return '-'
		}
		return moment(lesson.updated_at).format('YYYY-MM-DD');
	}

	const downloadLinkTemplate = (lesson) => {
		return lesson.reviewVideos.map(findInstructorLesson);
	}

	const createdDateTemplate = (video) => {
		return moment(video.created_at).format('YYYY-MM-DD');
	}

	function findInstructorLesson(video, index) {
		if (video.type === "instructor") return <Button variant="primary" key={index} href={video.filename} size="sm"><i className="bi bi-download"/></Button>;
	}

	return (
		<>
			<Helmet>
				<title>My Lessons | MyGolfDNA.com</title>
				<meta name="description" content="Lessons completed by your instructor"/>
			</Helmet>
				<div className="my-4">
					<Container>
						<Row>
							<Col sm={12} md={6} lg={6}>
								<div className="hstack gap-3">
									<div className="h3">My Lessons</div>
									<div className="ms-auto">{auth.user.planType === 'pro' ? 'Unlimited' : lessonCountRemaining} Lesson(s)
										Left
									</div>
									<div className="vr"/>
									<div className=""><Button href="/upload-videos" variant="success" disabled={!canSubmitLesson}>Start
										New Lesson</Button></div>
								</div>
								<hr/>
								{isLoading ? <LoadingDiv/> : !auth.user.planType ?
									<Alert variant="danger">You must be a premium member to submit lessons. <a href="/pricing">GET ACCESS
										NOW</a></Alert> : (
										<>
											<DataTable
												value={lessons}
												paginator
												rows={10}
												rowsPerPageOptions={[10, 25, 50]}
												tableStyle={{minWidth: '50rem'}}
												selectionMode="single"
											>
												<Column field="status" header="Status" sortable body={statusTemplate}/>
												<Column field="created_at" sortable header="Submitted Date" body={createdDateTemplate}/>
												<Column field="updated_at" sortable header="Reviewed Date" body={updatedOnDateTemplate}/>
												<Column header="Download Link" body={downloadLinkTemplate}/>
											</DataTable>
										</>
									)}
							</Col>
						</Row>
					</Container>
				</div>
		</>
	);
}

export default MyLessons;

import React from "react";
import {
	Button,
	FormGroup,
	Form,
	Row,
	Col,
	Card,
	Container,
} from "react-bootstrap";
import { Formik } from "formik";
import { ToastContainer, toast } from "react-toastify";
import moment from "moment";
import { useAuth } from "../../AuthUser";
import API from "../../api";
import {Helmet} from "react-helmet";
import {Navigate, useNavigate} from "react-router-dom";

const Shipping = () => {
	const { setUser, user } = useAuth();
	const navigate = useNavigate();
	console.log(user);
	if (user.isConsultCompleted) {
		return <Navigate to="/dna-profile" replace />;
	}
	const notify = (message) =>
		toast.error(message, {
			position: toast.POSITION.TOP_RIGHT,
		});

	function isValidDate(s) {
		// Assumes s is "mm/dd/yyyy"
		return moment(s, 'YYYY-MM-DD',true).isValid()

	}


	return (
	<>
		<Helmet>
			<title>Build Your DNA Profile | mygolfdna.com</title>
			<meta name="description" content="build your dna profile"/>
		</Helmet>
		<div className="mt-4">
			<Container>
				<Row>
					<div className="text-center h1 text-uppercase monument-font">Build Your DNA Profile</div>
					<Col sm={12} md={{ span: 8, offset: 2 }} lg={{ span: 6, offset: 3 }} xl={{ span: 6, offset: 3 }}>
						<p className="text-center text-black-50">Take five minutes and tell us about you and your game, show us some swings and setup your consult. Piece of cake!</p>
						<div className="container d-flex justify-content-center align-items-center">

							<div className="progresses">


								<div className="steps bg-dark-blue">
									<span className="font-weight-bold"><span className="font-weight-bold">1</span></span>
								</div>
								<div className="small d-none d-sm-block">Shipping</div>

								<span className="line"/>

								<div className="steps">
									<span className="font-weight-bold">2</span>
								</div>
								<div className="small d-none d-sm-block">About</div>


								<span className="line"/>

								<div className="steps">
									<span className="font-weight-bold">3</span>
								</div>
								<div className="small d-none d-sm-block">Upload</div>
								<span className="line"/>

								<div className="steps">
									<span className="font-weight-bold">4</span>
								</div>
								<div className="small d-none d-sm-block">Consult</div>

							</div>

						</div>
					<p className="mt-4 text-center text-secondary font-weight-bold">Confirm your shipping address so we can ship your LagStick training aid</p>
						<Formik
							enableReinitialize={true}
							initialValues={{firstName: '', lastName: '', address: '', address2: '', city: '', state: '', zip: '', birthday: ''}}

							validate={(values) => {
								const errors = {};
								if (!values.address) {
									errors.address = "Required";
								}

								if (!values.firstName) {
									errors.firstName = "Required";
								}

								if (!values.lastName) {
									errors.lastName = "Required";
								}

								if (!values.city) {
									errors.city = "Required";
								}
								if (!values.state) {
									errors.state = "Required";
								}
								if (!values.zip) {
									errors.zip = "Required";
								}
								if (!values.birthday) {
									errors.birthday = "Required";
								}
								console.log(values.birthday)
								if (!isValidDate(values.birthday)) {
									errors.birthday = "Invalid birthday"
								}

								return errors;
							}}
							onSubmit={(values, { setSubmitting, setErrors }) => {
								setTimeout(() => {
									API.post("shipping", {
										firstName: values.firstName,
										lastName: values.lastName,
										address: values.address,
										city: values.city,
										state: values.state,
										zip: values.zip,
										birthday: values.birthday
									})
										.then((res) => {
											navigate('/wizard/about', { replace: true });
											toast.success('Look out for an email about your lag stick!', {
												position: toast.POSITION.TOP_RIGHT,
											});
											setSubmitting(false);
										})
										.catch((error) => {
											setSubmitting(false);
											if (error.response) {
												notify(error.response.data.message);
											}
											if (
												error.response &&
												error.response.data.errors &&
												error.response.data.errors.length
											) {
												const result = error.response.data.errors.reduce(function (
														map,
														obj
													) {
														map[obj.property] = obj.message;
														return map;
													},
													{});
												setErrors(result);
											}
										});
								}, 400);
							}}
						>
							{({
									values,
									errors,
									touched,
									handleChange,
									handleSubmit,
									isSubmitting,
									setFieldValue,
								}) => (
								<>

											<Form noValidate className="form-group" onSubmit={handleSubmit}>
												<Row>
													<Col md={6}>
														<Form.Group className="form-floating mb-3">
															<Form.Control
																className=""
																type="text"
																name="firstName"
																id="firstName"
																onChange={handleChange}
																value={values.firstName}
																isInvalid={errors.firstName && touched.firstName}
																isValid={!errors.firstName && touched.firstName}
															/>
															<Form.Label className="" htmlFor="firstName">First Name</Form.Label>
															<div className="invalid-feedback">{errors.firstName}</div>
														</Form.Group>
													</Col>
													<Col md={6}>
														<Form.Group className="form-floating mb-3">
															<Form.Control
																className=""
																type="text"
																name="lastName"
																id="lastName"
																onChange={handleChange}
																value={values.lastName}
																isInvalid={errors.lastName && touched.lastName}
																isValid={!errors.lastName && touched.lastName}
															/>
															<Form.Label className="" htmlFor="lastName">Last Name</Form.Label>
															<div className="invalid-feedback">{errors.lastName}</div>
														</Form.Group>
													</Col>
													<Col md={12}>
														<Form.Group className="form-floating mb-3">
															<Form.Control
																className=""
																type="text"
																name="address"
																id="address"
																onChange={handleChange}
																value={values.address}
																isInvalid={errors.address && touched.address}
																isValid={!errors.address && touched.address}
															/>
															<Form.Label className="" htmlFor="address">Shipping Address</Form.Label>
															<div className="invalid-feedback">{errors.address}</div>
														</Form.Group>
													</Col>
													<Col md={6}>
														<Form.Group className="form-floating mb-3">
															<Form.Control
																className=""
																type="text"
																name="city"
																id="city"
																onChange={handleChange}
																value={values.city}
																isInvalid={errors.city && touched.city}
																isValid={!errors.city && touched.city}
															/>
															<Form.Label className="" htmlFor="city">City</Form.Label>
															<div className="invalid-feedback">{errors.city}</div>
														</Form.Group>
													</Col>
													<Col md={6}>
														<Form.Group className="form-floating mb-3">
															<Form.Control
																className=""
																type="text"
																name="state"
																id="state"
																onChange={handleChange}
																value={values.state}
																isInvalid={errors.state && touched.state}
																isValid={!errors.state && touched.state}
															/>
															<Form.Label className="" htmlFor="state">State</Form.Label>
															<div className="invalid-feedback">{errors.state}</div>
														</Form.Group>
													</Col>
													<Col md={6}>
														<Form.Group className="form-floating mb-3">
															<Form.Control
																className=""
																type="text"
																name="zip"
																id="zip"
																onChange={handleChange}
																value={values.zip}
																isInvalid={errors.zip && touched.zip}
																isValid={!errors.zip && touched.zip}
															/>
															<Form.Label className="" htmlFor="zip">Zip Code</Form.Label>
															<div className="invalid-feedback">{errors.zip}</div>
														</Form.Group>
													</Col>
													<Col md={6}>
														<Form.Group className="form-floating mb-3">
															<Form.Control
																className=""
																placeholder="mm/dd/yyyy"
																type="date"
																name="birthday"
																id="birthday"
																onChange={handleChange}
																value={values.birthday}
																isInvalid={errors.birthday && touched.birthday}
																isValid={!errors.birthday && touched.birthday}
															/>
															<Form.Label className="" htmlFor="birthday">Birthday</Form.Label>
															<div className="invalid-feedback">{errors.birthday}</div>
														</Form.Group>
													</Col>
												</Row>
												<div className="d-grid">
													<Button variant="primary" size="lg" type="submit" disabled={isSubmitting} className="">
														{isSubmitting ? (
															<>
													<span
														className="spinner-border spinner-border-sm"
														role="status"
														aria-hidden={true}
													/>{" "}
															</>
														) : (
															"Next Step"
														)}
													</Button>
												</div>
											</Form>
								</>
							)}
						</Formik>
					</Col>
				</Row>
			</Container>
		</div>
	</>
	);
};

export default Shipping;

import React, {useState, useEffect, forwardRef, useImperativeHandle} from 'react';
import { PickList } from 'primereact/picklist';
import API from "../api";
import moment from "moment";

const RecommendedVideosPickList = forwardRef((props, _ref) => {
	const [source, setSource] = useState([]);
	const [target, setTarget] = useState([]);

	useEffect(() => {
		API.get("admin/videos")
			.then(response => setSource(response.data))
			.finally(() => {});
	}, []);

	const onChange = (event) => {
		setSource(event.source);
		setTarget(event.target);
	};

	useImperativeHandle(_ref, () => ({
		getTargetVideos: () => {
			return target;
		},
	}));

	const truncate = (text, max, ending = '…') => text.length > max ? text.substr(0, max - ending.length) + ending : text;

	const itemTemplate = (item) => {
		return (
			<div>
				<div className="d-flex flex-row mb-2">
					<div className="d-inline-block px-2">
						<div className='position-relative'>
							<div style={{borderRadius: "4px", width: "150px", minHeight: "84px"}}>
								<img src={item.thumbnail} alt={item.title} style={{borderRadius: "4px", maxWidth: "150px", minHeight: "84px"}}/>
							</div>
						</div>
					</div>
					<div className="d-inline-block align-top px-2"><h6 style={{textWrap: 'wrap', fontSize: "14px"}} className="">{truncate(item.title,60, '...')}</h6>
						<p className="mb-1" style={{fontSize: 12}}>{item.Category.title}</p>
						<p className="text-black-50 mb-0" style={{fontSize: 10}}><i className="bi bi-clock"/> {moment(item.releaseOnDate).fromNow()}</p>
					</div>
				</div>
			</div>
		);
	};

	return (
		<div>
			<PickList source={source} target={target} onChange={onChange} itemTemplate={itemTemplate} filter filterBy="title" breakpoint="1400px"
								sourceHeader="Available" targetHeader="Selected" sourceStyle={{ height: '24rem' }} targetStyle={{ height: '24rem' }} />
		</div>
	);
});

export default React.memo(RecommendedVideosPickList);

import CommentForm from "./CommentForm";
import LessonComment from "./LessonComment";

function LessonComments() {

	return (
		<>
			<CommentForm/>
			<LessonComment/>
		</>
	)
}

export default LessonComments;

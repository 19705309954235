import React from "react";
import {
	Button,
	FormGroup,
	Form,
	Row,
	Col,
	Card,
} from "react-bootstrap";
import { Formik } from "formik";
import { ToastContainer, toast } from "react-toastify";
import moment from "moment";
import { useAuth } from "../../AuthUser";
import API from "../../api";

const Details = () => {
	const { setUser, user } = useAuth();

	const notify = (message) =>
		toast.error(message, {
			position: toast.POSITION.TOP_RIGHT,
		});

	function isValidDate(s) {
		// Assumes s is "mm/dd/yyyy"
		return moment(s, 'YYYY-MM-DD',true).isValid()

	}

	const getData = () => user || { firstName: "", lastName: "", email: "", birthday: "" };

	return (
		<Formik
			enableReinitialize={true}
			initialValues={getData()}

			validate={(values) => {
				const errors = {};
				if (!values.email) {
					errors.email = "Required";
				} else if (
					!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
				) {
					errors.email = "Invalid email address";
				}

				if (!values.firstName) {
					errors.firstName = "Required";
				}
				if (!values.lastName) {
					errors.lastName = "Required";
				}
				if (!values.birthday) {
					errors.birthday = "Required";
				}
				console.log(values.birthday)
				if (!isValidDate(values.birthday)) {
					errors.birthday = "Invalid birthday"
				}

				return errors;
			}}
			onSubmit={(values, { setSubmitting, setErrors }) => {
				setTimeout(() => {
					API.put("accounts/me", {
						email: values.email,
						birthday: values.birthday,
						firstName: values.firstName,
						lastName: values.lastName,
					})
						.then((res) => {
							setUser(res.data);
							toast.success('Account updated successfully.', {
								position: toast.POSITION.TOP_RIGHT,
							});
							setSubmitting(false);
						})
						.catch((error) => {
							setSubmitting(false);
							if (error.response) {
								notify(error.response.data.message);
							}
							if (
								error.response &&
								error.response.data.errors &&
								error.response.data.errors.length
							) {
								const result = error.response.data.errors.reduce(function (
										map,
										obj
									) {
										map[obj.property] = obj.message;
										return map;
									},
									{});
								setErrors(result);
							}
						});
				}, 400);
			}}
		>
			{({
					values,
					errors,
					touched,
					handleChange,
					handleSubmit,
					isSubmitting,
				  setFieldValue,
				}) => (
				<>
					<Card>
						<Card.Header as="h5">Basic Info</Card.Header>
						<Card.Body>
							<Form noValidate className="form-group" onSubmit={handleSubmit}>
								<Row>
									<Col sm={12} md={6}>
										<Form.Group className="form-floating mb-3">
											<Form.Control
												className=""
												placeholder="First name"
												type="text"
												name="firstName"
												id="firstname"
												onChange={handleChange}
												value={values.firstName}
												isInvalid={errors.firstName && touched.firstName}
												isValid={!errors.firstName && touched.firstName}
											/>
											<Form.Label className="" htmlFor="firstname">First name</Form.Label>
											<div className="invalid-feedback">{errors.firstName}</div>
										</Form.Group>
									</Col>
									<Col sm={12} md={6}>
										<Form.Group className="form-floating mb-3">
											<Form.Control
												className=""
												placeholder="Last name"
												type="text"
												name="lastName"
												id="lastname"
												onChange={handleChange}
												value={values.lastName}
												isInvalid={errors.lastName && touched.lastName}
												isValid={!errors.lastName && touched.lastName}
											/>
											<Form.Label className="" htmlFor="lastname">Last name</Form.Label>
											<div className="invalid-feedback">{errors.lastName}</div>
										</Form.Group>
									</Col>
									<Col sm={12} md={6}>
										<Form.Group className="form-floating mb-3">
											<Form.Control
												className=""
												placeholder="Email address"
												type="email"
												name="email"
												id="email"
												onChange={handleChange}
												value={values.email}
												isInvalid={errors.email && touched.email}
												isValid={!errors.email && touched.email}
											/>
											<Form.Label className="" htmlFor="email">Email address</Form.Label>
											<div className="invalid-feedback">{errors.email}</div>
										</Form.Group>
									</Col>
									<Col sm={12} md={6}>
										<Form.Group className="form-floating mb-3">
											<Form.Control
												className=""
												placeholder="mm/dd/yyyy"
												type="date"
												name="birthday"
												id="birthday"
												onChange={handleChange}
												value={values.birthday}
												isInvalid={errors.birthday && touched.birthday}
												isValid={!errors.birthday && touched.birthday}
											/>
											<Form.Label className="" htmlFor="birthday">Birthday</Form.Label>
											<div className="invalid-feedback">{errors.birthday}</div>
										</Form.Group>
									</Col>
								</Row>
								<FormGroup className="">
									<Button variant="primary" size="lg" type="submit" disabled={isSubmitting}>
										{isSubmitting ? (
											<>
													<span
														className="spinner-border spinner-border-sm"
														role="status"
														aria-hidden={true}
													/>{" "}Saving changes
											</>
										) : (
											"Save changes"
										)}
									</Button>
								</FormGroup>
							</Form>
						</Card.Body>
					</Card>
				</>
			)}
		</Formik>
	);
};

export default Details;

import {useEffect, useState} from "react";
import {useAuth} from "../../AuthUser";
import {Navigate, useLocation, useNavigate, useParams} from "react-router-dom";
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import VideoUpload from "./VideoUpload";
import API from "../../api";
import Spinner from "react-bootstrap/Spinner";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Badge from "react-bootstrap/Badge";
import Stack from "react-bootstrap/Stack";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import ProgressTracker from "../../components/Admin/ProgressTracker";
import {Column} from "primereact/column";
import {DataTable} from "primereact/datatable";
import * as React from "react";

function useQuery() {
	const { search } = useLocation();

	return React.useMemo(() => new URLSearchParams(search), [search]);
}

function UploadVideos() {
	let auth = useAuth();
	let navigate = useNavigate();
	let { lessonId } = useParams();
	let query = useQuery();

	const [lesson, setLesson] = useState([])
	const [isLoading, setLoading] = useState(true)

	const fetchData = async () => {
		const response = await API.get(`admin/lessons/${lessonId}`);
		const data = response.data;
		console.log(response);
		setLesson(data);
	}

	useEffect(() => {
		setLoading(true);
		fetchData().then().finally(() => setLoading(false))
	}, [])

	const deleteLesson = async (lessonId) => {
		let text = "Are you sure you want to delete this lesson? It cannot be undone.";
		if (window.confirm(text) === true) {
			await API.delete(`admin/lessons/${lessonId}`);
			navigate('/admin/lessons');
		}

	}

	if(!auth.user) {
		return <Navigate to="/signin" replace/>
	}
	if (!auth.user.admin) {
		return <Navigate to="/" replace />;
	}

	const consultBodyTemplate = (lesson) => {
		if (lesson.consult === 1) {
			return <Badge bg="info">Consult</Badge>
		}
	}

	const latestInstructorCommentTemplate = (comment) => {
		if (comment) {
			return comment;
		}
		return 'No Instructor Comment'
	}

	const planTypeTemplate = (plan) => {
		plan = plan || '';
		if (plan.toLowerCase().includes('premier+')) {
			return <Badge bg="secondary"><span className="text-capitalize">{plan}</span></Badge>
		}
		if (plan.toLowerCase().includes('premier')) {
			return <Badge bg="warning" text="dark"><span className="text-capitalize">{plan}</span></Badge>
		}
		if (plan.toLowerCase().includes('pro')) {
			return <Badge bg="light"><span className="text-capitalize">{plan}</span></Badge>
		}
		return <Badge bg="light" text="dark"><span className="text-capitalize">{plan || 'Free'}</span></Badge>
	}


	return (
		isLoading ? <div style={{textAlign: 'center'}}> <Spinner animation="border" /></div> :
			<>
			<div className="mt-3">
				<Row>
					<Col>
						<div className="d-flex justify-content-between align-items-center mb-2">
							<div>

							</div>
							<div>
								<Button onClick={() => deleteLesson(lesson.lesson.id)} variant="danger"><i className="bi bi-trash"/></Button>
							</div>
						</div>
					</Col>
				</Row>
					<Row>
						<Col>
							<Card>
								<Card.Body>
									<div className="d-flex justify-content-between align-items-center mb-2">
										<div>
											<Card.Title><a href={`/admin/users/${lesson.lesson.accountId}`}> {lesson.account.firstName} {lesson.account.lastName}</a> {planTypeTemplate(lesson.account.planType)} {consultBodyTemplate(lesson)}</Card.Title>
											<Card.Subtitle className="mb-2 text-muted">{lesson.account.email}</Card.Subtitle>
										</div>

									</div>

									<h6>User Notes:</h6>
									<p>{lesson.lesson.notes}</p>
									<Stack direction="horizontal" gap={5} className="mt-4">
										<div>
											<h6>Lessons Remaining:</h6>
											<p className="small">{lesson.remainingLessonCount}</p>
										</div>
										<div>
											<h6>Last Lesson Date:</h6>
											<p className="small">{lesson.lastLessonDate}</p>
										</div>
										<div>
											<h6>Videos:</h6>
											<ul className="list-unstyled">
												{lesson.lesson.reviewVideos.map((video,index) => {
													if (video.type === 'user') {
														return <li key={index}><a key={index} href={video.filename}> Video {index+1}</a></li>
													}
													return '';
												})}
											</ul>
										</div>
									</Stack>
									<h6>Latest Instructor Comment:</h6>
									<p>{latestInstructorCommentTemplate(lesson.lessonInstructorComments[0]?.instructor_notes)}</p>
								</Card.Body>
							</Card>
						</Col>
						<Col>
								<Card className="lesson-progress-tracker">
									<Card.Header as="h6"><div className="hstack gap-3">
										<div className="">Progress Tracker</div>
									</div>
									</Card.Header>
									<Card.Body>
										<ProgressTracker
											handicaps={lesson.handicap}
										/>
									</Card.Body>
								</Card>
						</Col>
					</Row>
				<div className="mt-5">
				<Row>
					<Col>
						<Tabs
						defaultActiveKey="upload"
						id="justify-tab-example"
						className="mb-3"
						justify
						>
							<Tab eventKey="upload" title="Upload">
								<VideoUpload
									redirectTo="/admin/lessons"
									lessonId={lesson.lesson.id}
								/>
							</Tab>
							<Tab eventKey="comments" title="Instructor Comments">
								<DataTable
									style={{fontSize: '14px'}}
									value={lesson.lessonInstructorComments}
									rows={10}
									rowsPerPageOptions={[10, 25, 50]}
									//tableStyle={{minWidth: '50rem'}}
								>
									<Column field="instructor_notes" header="Instructor Notes" />
									<Column field="created_at" header="Lesson Date" />
								</DataTable>
							</Tab>
							<Tab eventKey="wizard" title="Wizard">
								<ul className="list-unstyled">
									<li>Bunker Play: {lesson.wizard.bunkerPlay}</li>
									<li>Chipping: {lesson.wizard.chipping}</li>
									<li>Course Management: {lesson.wizard.courseManagement}</li>
									<li>Driver Accuracy: {lesson.wizard.driverAccuracy}</li>
									<li>Driver Distance: {lesson.wizard.driverDistance}</li>
									<li>Equipment: {lesson.wizard.equipment}</li>
									<li>Fairways, Woods and Hybrids: {lesson.wizard.fairwaysWoodsHybrids}</li>
									<li>Frustrations: {lesson.wizard.frustrates}</li>
									<li>Goals: {lesson.wizard.goals}</li>
									<li>Injuries: {lesson.wizard.injuries}</li>
									<li>Iron Play: {lesson.wizard.ironPlay}</li>
									<li>Pitching: {lesson.wizard.pitching}</li>
									<li>Years Played: {lesson.wizard.practice}</li>
									<li>Putting: {lesson.wizard.putting}</li>
									<li>Rounds Played: {lesson.wizard.rounds}</li>
									<li>Training Aids Used: {lesson.wizard.training}</li>
								</ul>
							</Tab>
						</Tabs>
					</Col>
				</Row>
			</div>
			</div>
		</>
	);
}

export default UploadVideos;

import * as React from "react";
import { Outlet } from "react-router-dom";
import LoggedInNavigation from "./../components/Navigations/LoggedInNavigation";
import {Container, Row, Col} from "react-bootstrap";
import Footer from "../components/Footer";

function DefaultLayout({ backgroundColor = "#fff"}) {
	return (
		<div style={{backgroundColor, minHeight: "100vh"}}>
			<LoggedInNavigation hideAuthButton={true} />
			<div style={{minHeight: "50rem"}}>
				<Outlet/>

			</div>
			<Footer/>
		</div>
	);
}

export default DefaultLayout;

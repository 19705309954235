import {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {
	Button,
	FormGroup,
	Form,
	Row,
	Col,
	Stack
} from "react-bootstrap";
import { Formik } from "formik";
import { toast } from "react-toastify";
import API from "../../api";
import {Helmet} from "react-helmet";

const EditPlan = () => {
	const navigate = useNavigate();
	let { planId } = useParams();

	const [plan, setPlan] = useState([])
	const [instructors, setInstructors] = useState([])
	const [isLoading, setLoading] = useState(false)

	const notify = (message) =>
		toast.error(message, {
			position: toast.POSITION.TOP_RIGHT,
		});

	const success = (message) =>
		toast.success(message, {
			position: toast.POSITION.TOP_RIGHT,
		});

	useEffect(() => {
		setLoading(true);
		API.get(`/admin/plan/${planId}`)
			.then(response => setPlan(response.data))
			.finally(() => setLoading(false));

		API.get(`admin/instructors`)
			.then(response => setInstructors(response.data))

	}, [])

	function createSelectItems() {
		return instructors.map((instructor,index) => {
				return <option key={index} value={instructor.id}>{instructor.firstName} {instructor.lastName}</option>
		})
	}

	return (
		<Formik
			enableReinitialize={true}
			initialValues={{
				planName: plan.planName || "",
				cadence: plan.cadence || "",
				amount: plan.amount || 0,
				source: plan.source || "square",
				lessonCount: plan.lessonCount || 0,
				description: plan.description || "",
				terms: plan.terms || "",
				visible: plan.visible,
				active: plan.active,
				userCount: plan.userCount || 0,
				consult: plan.consult,
				mostPopular: plan.mostPopular,
				instructorId: plan.instructorId || 1,
			}}
			validate={(values) => {
				const errors = {};
				if (!values.planName) {
					errors.planName = "Required";
				}
				if (!values.amount) {
					errors.amount = "Required";
				}
				if (!values.description) {
					errors.description = "Required";
				}
				if (!values.terms) {
					errors.terms = "Required";
				}
				if (!values.lessonCount) {
					errors.lessonCount = "Required";
				}
				if (!values.instructorId) {
					errors.instructorId = "Required";
				}

				return errors;
			}}
			onSubmit={(values, { setSubmitting, setErrors }) => {
				setTimeout(() => {
					console.log({
						planName: values.planName,
						cadence: values.cadence,
						amount: values.amount,
						source: values.source,
						lessonCount: values.lessonCount,
						description: values.description,
						terms: values.terms,
						visible: values.visible,
						active: values.active,
						userCount: values.userCount,
						consult: values.consult,
						mostPopular: values.mostPopular,
						instructorId: values.instructorId,
					});
					API.put(`admin/plan/${plan.id}`, {
						planName: values.planName,
						cadence: values.cadence,
						amount: values.amount,
						source: values.source,
						lessonCount: values.lessonCount,
						description: values.description,
						terms: values.terms,
						visible: values.visible,
						active: values.active,
						userCount: values.userCount,
						consult: values.consult,
						mostPopular: values.mostPopular,
						instructorId: values.instructorId,
					}, {
						headers: {
							"Content-Type": "multipart/form-data"
						}
					})
						.then((res) => {
							setSubmitting(false);
							success('Successfully updated plan');
							navigate('/admin/plans');
						})
						.catch((error) => {
							setSubmitting(false);
							if (error.response) {
								notify(error.response.data.message);
							}
							if (
								error.response &&
								error.response.data.errors &&
								error.response.data.errors.length
							) {
								const result = error.response.data.errors.reduce(function (
										map,
										obj
									) {
										map[obj.property] = obj.message;
										return map;
									},
									{});
								setErrors(result);
							}
						});
				}, 400);
			}}
		>
			{({
					values,
					errors,
					touched,
					handleChange,
					handleSubmit,
					isSubmitting,
					setFieldValue,
				}) => (
				<>
					<Helmet>
						<title>Edit Video | MyGolfDNA.com</title>
						<meta name="description" content="edit video for website"/>
					</Helmet>
						<Row>
							<Col md={8}>

								<Form noValidate className="mt-5 form-group" onSubmit={handleSubmit}>
									<h3>Edit Plan</h3>
									<Row>
										<Col md={12}>
											<Form.Group className="form-floating mb-3">
												<Form.Control
													className=""
													placeholder="Plan Name"
													type="text"
													name="planName"
													id="planName"
													onChange={handleChange}
													value={values.planName}
													isInvalid={errors.planName && touched.planName}
													isValid={!errors.planName && touched.planName}
												/>
												<Form.Label className="" htmlFor="title">Plan Name</Form.Label>
												<div className="invalid-feedback">{errors.planName}</div>
											</Form.Group>
										</Col>
										<Col md={3}>
											<Form.Group className="form-floating mb-3">
												<Form.Control
													className=""
													placeholder="Amount"
													type="number"
													name="amount"
													id="amount"
													onChange={handleChange}
													value={values.amount}
													isInvalid={errors.amount && touched.amount}
													isValid={!errors.amount && touched.amount}
													disabled={true}
												/>
												<Form.Label className="" htmlFor="vimeId">Amount</Form.Label>
												<div className="invalid-feedback">{errors.amount}</div>
											</Form.Group>
										</Col>
									</Row>
										<Form.Group className="form-floating mb-3">
											<Form.Control
												className=""
												placeholder="Lesson Count"
												type="number"
												name="lessonCount"
												id="lessonCount"
												onChange={handleChange}
												value={values.lessonCount}
												isInvalid={errors.lessonCount && touched.lessonCount}
												isValid={!errors.lessonCount && touched.lessonCount}
											/>
											<Form.Label className="" htmlFor="vimeId">Lesson Count</Form.Label>
											<div className="invalid-feedback">{errors.lessonCount}</div>
										</Form.Group>
											<Form.Group className="form-floating mb-3">
												<Form.Control
													className=""
													placeholder="Plan User Limit"
													type="number"
													name="userCount"
													id="userCount"
													onChange={handleChange}
													value={values.userCount}
													isInvalid={errors.userCount && touched.userCount}
													isValid={!errors.userCount && touched.userCount}
												/>
												<Form.Label className="" htmlFor="userCount">Plan User Limit</Form.Label>
												<div className="invalid-feedback">{errors.userCount}</div>
											</Form.Group>
									<Form.Group className="mb-3">
									<Form.Label className="" htmlFor="instructor">Instructor</Form.Label>
										<Form.Select
											aria-label="instructor"
											name="instructorId"
											id="instructor"
											onChange={handleChange}
											value={values.instructorId}
											isInvalid={errors.instructorId && touched.instructorId}
											isValid={!errors.instructorId && touched.instructorId}
										>
											{createSelectItems()}
										</Form.Select>
									</Form.Group>
									<Form.Group className="mb-3">
										<Form.Control
											placeholder="description of plan."
											as="textarea"
											rows="5"
											name="description"
											id="description"
											onChange={handleChange}
											value={values.description}
											isInvalid={errors.description && touched.description}
											isValid={!errors.description && touched.description}
										/>
										<Form.Label className="" htmlFor="free">Description</Form.Label>
										<div className="invalid-feedback">{errors.description}</div>
									</Form.Group>
										<Form.Group className="mb-3">
											<Form.Control
												placeholder="terms of plan."
												as="textarea"
												rows="5"
												name="terms"
												id="terms"
												onChange={handleChange}
												value={values.terms}
												isInvalid={errors.terms && touched.terms}
												isValid={!errors.terms && touched.terms}
											/>
											<Form.Label className="" htmlFor="free">Terms</Form.Label>
											<div className="invalid-feedback">{errors.terms}</div>
										</Form.Group>
									<Form.Group className="mb-3">
										<Form.Label className="" htmlFor="free">Active</Form.Label>
										<Form.Select
											aria-label="active"
											name="active"
											id="active"
											onChange={handleChange}
											value={values.active}
											isInvalid={errors.active && touched.active}
											isValid={!errors.active && touched.active}
										>
											<option value="true">Yes</option>
											<option value="false">No</option>
										</Form.Select>
									</Form.Group>
									<Form.Group className="mb-3">
										<Form.Label className="" htmlFor="free">Visible</Form.Label>
										<Form.Select
											aria-label="visible"
											name="visible"
											id="visible"
											onChange={handleChange}
											value={values.visible}
											isInvalid={errors.visible && touched.visible}
											isValid={!errors.visible && touched.visible}
										>
											<option value="true">Yes</option>
											<option value="false">No</option>
										</Form.Select>
									</Form.Group>
									<Form.Group className="mb-3">
										<Form.Label className="" htmlFor="active">Consult</Form.Label>
										<Form.Select
											aria-label="consult"
											name="consult"
											id="consult"
											onChange={handleChange}
											value={values.consult}
											isInvalid={errors.consult && touched.consult}
											isValid={!errors.consult && touched.consult}
										>
											<option value="true">Yes</option>
											<option value="false">No</option>
										</Form.Select>
									</Form.Group>
									<Form.Group className="mb-3">
										<Form.Label className="" htmlFor="mostPopular">Most Popular</Form.Label>
										<Form.Select
											aria-label="mostPopular"
											name="mostPopular"
											id="mostPopular"
											onChange={handleChange}
											value={values.mostPopular}
											isInvalid={errors.mostPopular && touched.mostPopular}
											isValid={!errors.mostPopular && touched.mostPopular}
										>
											<option value="true">Yes</option>
											<option value="false">No</option>
										</Form.Select>
									</Form.Group>
									<FormGroup className="mt-5">
										<Stack direction="horizontal" gap={3}>
											<Button variant="primary" size="lg" type="submit" disabled={isSubmitting}>
												{isSubmitting ? (
													<>
													<span
														className="spinner-border spinner-border-sm"
														role="status"
														aria-hidden={true}
													/>{" "}
													</>
												) : (
													"Submit"
												)}
											</Button>
										</Stack>
									</FormGroup>
								</Form>
							</Col>
						</Row>
				</>
			)}
		</Formik>
	);
};

export default EditPlan;

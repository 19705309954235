import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import LogoIcon from "./../../assets/img/logo2.png";

function EmptyNavigation() {

	return (
		<>
			<Navbar variant="dark" className="bg-dark-blue" style={{minHeight: '68px'}}>
				<Container>
					<Navbar.Brand href="/" style={{letterSpacing: '1px'}} className="monument-font">
						MYGOLF<span className="text-secondary">DNA</span>
					</Navbar.Brand>
				</Container>
			</Navbar>
		</>
	);
}

export default EmptyNavigation;

import {Col, Container, Row} from "react-bootstrap";

function Footer ({ className }) {
	let textcss = 'd-flex flex-row-reverse';
	if (className === "bg-dark-blue") {
		textcss = 'd-flex flex-row-reverse text-white-50';
	}
	return (
		<>
			<footer className="bg-dark-blue p-4 mt-4" >
				<Container>
					<Row>
						<Col>
							<div className="d-flex text-white-50">
								<div className="hstack gap-2">
								<a className="text-white-50 small" href="/policies/privacy">Privacy Policy</a>
								<a className="text-white-50 small visually-hidden" href="/policies/terms">Terms of Service</a>
								</div>
							</div>
						</Col>
						<Col>
							<div className="d-flex flex-row-reverse text-white-50">
								<div className="small">© 2023 MyGolfDNA.com</div>
							</div>
						</Col>
					</Row>
				</Container>
			</footer>
		</>
	)

}

export default Footer;

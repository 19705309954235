import {useEffect, useState} from "react";
import {useAuth} from "../../AuthUser";
import {Navigate, useLocation, useNavigate, useParams} from "react-router-dom";
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import VideoUpload from "./VideoUpload";
import API from "../../api";
import Spinner from "react-bootstrap/Spinner";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Badge from "react-bootstrap/Badge";
import Stack from "react-bootstrap/Stack";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import ProgressTracker from "../../components/Admin/ProgressTracker";
import {Column} from "primereact/column";
import {DataTable} from "primereact/datatable";


function CreateLesson() {
	let auth = useAuth();
	let navigate = useNavigate();
	let { userId } = useParams();

	const [user, setUser] = useState([])
	const [isLoading, setLoading] = useState(true)

	const fetchData = async () => {
		const response = await API.get(`admin/users/${userId}`);
		const data = response.data;
		setUser(data);
	}

	useEffect(() => {
		setLoading(true);
		fetchData().then().finally(() => setLoading(false))
	}, [])


	if(!auth.user) {
		return <Navigate to="/signin" replace/>
	}
	if (!auth.user.admin) {
		return <Navigate to="/" replace />;
	}

	return (
		isLoading ? <div style={{textAlign: 'center'}}> <Spinner animation="border" /></div> :
			<>
			<div className="mt-3">
				<div className="mt-5">
				<Row>
					<Col>
						<VideoUpload
							redirectTo={`/admin/users/${userId}`}
							userId={userId}
						/>
					</Col>
				</Row>
			</div>
			</div>
		</>
	);
}

export default CreateLesson;

import * as React from "react";
import {useNavigate} from "react-router-dom";
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import {Helmet} from "react-helmet";
import Badge from "react-bootstrap/Badge";
import API from "../../api";
import {useEffect, useState} from "react";
import moment from "moment";

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';


function LessonComments() {
	let navigate = useNavigate();

	const [lessonComments, setLessonComments] = useState([])
	const [isLoading, setLoading] = useState(false)

	useEffect(() => {
		setLoading(true);
		API.get("admin/lessonComments")
			.then(response => setLessonComments(response.data))
			.finally(() => setLoading(false));
	}, [])

	const onRowSelect = (event) => {
		navigate(`/lesson/${event.data.review_id}`)
	};

	const createdDateTemplate = (video) => {
		return moment(video.created_at).format('YYYY-MM-DD HH:mm:ss');
	}

	return (
		<>
			<Helmet>
				<title>Lesson Comments | MyGolfDNA.com</title>
				<meta name="description" content="Categories"/>
			</Helmet>
			<div className="mt-4">
				<Row>
					<Col>
						<h2>Lesson Comments</h2>
						<div className="card">
							<DataTable
								value={lessonComments}
								paginator rows={10}
								rowsPerPageOptions={[10, 25, 50]}
								tableStyle={{ minWidth: '50rem' }}
								selectionMode="single"
								onRowSelect={onRowSelect}
							>
								<Column field="comment" header="Comment"/>
								<Column field="created_at" sortable header="Created Date" body={createdDateTemplate}/>
							</DataTable>
						</div>
					</Col>
				</Row>
			</div>
		</>
	);
}

export default LessonComments;

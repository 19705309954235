import * as React from "react";
import { Outlet } from "react-router-dom";
import EmptyNavigation from "./../components/Navigations/EmptyNavigation";
import "../assets/css/AuthLayout.css";

function DefaultLayout({ backgroundColor = "#fff"}) {
	return (
		<div style={{backgroundColor, minHeight: "100vh"}}>
			<EmptyNavigation />
			<Outlet/>
		</div>
	);
}

export default DefaultLayout;
